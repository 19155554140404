import {
  paymentWays,
  ItemTransactionPaymentType,
} from '@/libs/acl/Lookups';

export default {
  data() {
    return {
      // lookups entites
      paymentWays: paymentWays,
      itemTransactionTypes: ItemTransactionPaymentType,
      currentType: 'sales',
      students: [],
      paymentMethods: [],
      // invoice Model
      selectedItem: {
        code: '',
        transactionDate: '',
        discountTypeId: 0,
        notes: '',
        sourceTransactionId: null,
        paymentType: 'credit',
        studentId: null,
        total: 0,
        discountPercentage: 0,
        discountValue: 0,
        netBeforeTaxes: 0,
        totalTaxes: 0,
        net: 0,
        invoiceLines: [],
        invoicePayments: [],
        branchId: this.branchId,
        nationalityTaxable: false
      },
      noPayments: [
        'deservedFees-new',
        'deservedFees-edit',
        'deservedFeesCollection'
      ]
    };
  },
  computed: {
    status() {
      if (this.selectedItem.isComplete) return this.$t('complete');
      if (this.selectedItem.isPosted) return this.$t('posted');
      if (this.selectedItem.invoiceLines.some((i) => i.consumedQuantity)) return this.$t('usedInAnother');
      return null;
    },
    isClosed() {
      // return !!this.status;
      return (
        this.selectedItem.isPosted || this.selectedItem.isComplete
        || this.selectedItem.invoiceLines.some((i) => i.consumedQuantity)
      );
    },
    hasSource() {
      return !!this.selectedItem.sourceTransactionId;
    },
    isEditable() {
      return this.selectedItem.id || this.selectedItem.sourceTransactionId;
    },
    companyTax() {
      return [
        {
          taxTypeArabicName: this.$t('companyTax'),
          taxTypeEnglishName: this.$t('companyTax'),
          taxTypeId: '',
          taxPercentage: 15, // this.company.taxPercentage,
          taxValue: 0,
        },
      ];
    },
    isLineBaseDiscount() {
      return this.currentBranch.discountLevel === 'items';
    },
    tableColumns() {
      return [
        {
          key: 'paymentMethod',
          label: this.$t('paymentType'),
          isLocale: true,
          sortable: false,
        },
        {
          key: this.isRight
            ? 'otherPaymentMethodArabicName'
            : 'otherPaymentMethodEnglishName',
          label: this.$t('paymentMethod'),
          sortable: false,
          formatter: (key, value, item) => {
            if (!item.otherPaymentMethodArabicName) return this.isRight ? this.$t('cash') : this.$t('cash');
            return this.isRight
              ? item.otherPaymentMethodArabicName
              : item.otherPaymentMethodEnglishName;
          },
        },
        {
          key: 'totalValue',
          label: this.$t('value'),
          sortable: false,
          formatter: (key, value, item) => {
            if (item.paymentMethod === 'other') {
              return item.value
            } if (item.paymentMethod === 'cash') {
              return item.totalValue
            }
          },
        },
        {
          key: 'cashPaid',
          label: this.$t('paid'),
          sortable: false,
          formatter: (key, value, item) => {
            if (item.paymentMethod === 'other') {
              return item.value
            } if (item.paymentMethod === 'cash') {
              return item.cashPaid
            }
          },
        },
        {
          key: 'remaining',
          label: this.$t('remaindered'),
          sortable: false,
          formatter: (key, value, item) => {
            if (item.paymentMethod === 'other') {
              return 0
            } if (item.paymentMethod === 'cash') {
              return this.paid - item.totalValue
            }
          },
          footer: () => this.fraction(this.remaindered),
          tdClass: this.$route.name !== 'pos-invoice' || this.$route.name !== 'pos-invoice-edit' ? '' : 'd-none',
          thClass: this.$route.name !== 'pos-invoice' || this.$route.name !== 'pos-invoice-edit' ? '' : 'd-none',
        },
        { key: 'actions' },
      ];
    },
    paid() {
      return this.selectedItem.invoicePayments.reduce((sum, item) => {
        // sum += parseFloat(item.cashPaid);
        item.totalValue = this.selectedItem.net
        if (item.paymentMethod === 'cash') {
          item.value = item.cashPaid
        }
        sum += parseFloat(item.value);
        return sum;
      }, 0);
    },
  },
  mounted() {
    const { query } = this.$route;

    Object.keys(query)
      .filter((key) => this.selectedItem[key] !== undefined)
      .forEach((key) => {
        this.selectedItem[key] = query[key];
      });
  },
  methods: {
    computeTotalDiscount() {
      // compute discount for item based
      if (this.isLineBaseDiscount) {
        let itemTotal = 0;
        let itemDiscount = 0;
        this.selectedItem.invoiceLines.forEach((item) => {
          itemDiscount += parseFloat(item.discountValue);
          itemTotal += parseFloat(item.total);
        });
        this.selectedItem.total = itemTotal;
        this.selectedItem.discountValue = itemDiscount;
        this.selectedItem.discountPercentage = this.fraction(
          (itemDiscount / itemTotal) * 100
        );
      }
    },
    computeTotalTaxes() {
        var tax = 0;
        var totaPriceBeforTax = 0;
        var totalNet = 0;
        var allTotal = 0;
        this.selectedItem.invoiceLines.forEach((val) => {
            tax = val.tax;
            totaPriceBeforTax = val.priceBeforeTax;
            if (val.priceNotIncludeTax) {
              totalNet = parseFloat(val.priceBeforeTax) + parseFloat(val.tax);
          } else {
            totalNet = val.net;
          }
          allTotal = val.total;
        this.selectedItem.totalTaxes += parseFloat(tax);
        this.selectedItem.netBeforeTaxes += parseFloat(totaPriceBeforTax);
        this.selectedItem.net += parseFloat(totalNet);
        this.selectedItem.total += parseFloat(allTotal);
      });
    },
    computeTotalWithoutTaxes() {
      this.selectedItem.net = this.selectedItem.total - this.selectedItem.discountValue;
    },
    getSelecteItemObj() {
      this.selectedItem = {
        code: '',
        transactionDate: '',
        transactionTime: '',
        discountTypeId: 0,
        notes: '',
        // sourceTransactionId: null,
        paymentType: 'credit',
        studentId: null,
        total: 0,
        discountPercentage: 0,
        discountValue: 0,
        netBeforeTaxes: 0,
        totalTaxes: 0,
        net: 0,
        invoiceLines: [],
        invoicePayments: [],
        branchId: this.branchId
      };
      this.setupDefualts();
      // this.getAppliedTaxes();
    },

    havePayment() {
      return !this.noPayments.includes(this.$route.name);
    },

    getModel() {
      const model = JSON.parse(JSON.stringify(this.selectedItem));
      // remove unneeded properities from model items
      model.invoiceLines = model.invoiceLines.map((item) => {
        return {
          invoiceId: model.id ? item.invoiceId : 0,
          lineSerial: item.lineSerial,
          serviceId: item.serviceId,
          price: item.price || 0,
          total: item.total || 0,
          discountPercentage: item.discountPercentage || 0,
          discountValue: item.discountValue || 0,
          net: item.net || 0,
          notes: item.notes || '',
          tax: item.tax || 0,
          priceBeforeTax: item.priceBeforeTax || 0,
          priceNotIncludeTax: item.priceNotIncludeTax || 0,
          priceTrafficedValue: item.priceTrafficedValue || 0,
          discountTrafficedValue: item.discountTrafficedValue || 0
        };
      });

      if (this.havePayment()) {
        model.invoicePayments = model.invoicePayments.map((item) => {
          return {
            invoiceId: item.invoiceId || 0,
            lineSerial: item.lineSerial,
            value: item.value,
            cashPaid: item.cashPaid,
            paymentMethod: item.paymentMethod,
            otherPaymentMethodId: item.otherPaymentMethodId || 0,
            referenceNumber: item.referenceNumber,
          };
        });
      }
      return model;
    },
    preparePaymentModel() {
      this.paymentMethod.lineSerial = this.selectedItem.invoicePayments.length + 1;
      this.paymentMethod.safeArabicName = this.safeTransactions.safeArabicName;
      this.paymentMethod.safeEnglishName = this.safeTransactions.safeEnglishName;
      this.paymentMethod.safeId = null;
      this.paymentMethod.otherPaymentMethodId = this.paymentMethod.otherPaymentMethodId || null;
      if (this.payemntType === 'Cash') {
        this.paymentMethod.paymentMethod = 'cash';
        this.paymentMethod.safeId = this.safeTransactions.safeId || null;
      } else {
        this.paymentMethod.paymentMethod = 'other';
      }
    },
    saveAsDraft() {
      if (this.selectedItem.studentId && this.selectedItem.invoiceLines.length > 0) {
        // Add
      }
    },
    setupDefualts() {
      this.selectedItem.transactionDate = this.today;
      this.selectedItem.transactionTime = this.getTime();
      this.selectedItem.paymentType = 'credit';
      // this.selectedItem.transactionTime = this.getTime();
      this.selectedItem.studentId = this.selectedItem.studentId || null;
      const studentId = this.$route.query.studentId;
      if (studentId > 0) {
        this.selectedItem.studentId = studentId;
      }
    },
    beforeSaveValidationDealer() {
      if (!this.selectedItem.studentId) {
        // alert here
        this.doneAlert({ text: this.$t('youShoudSelectStudent'), type: 'error' });
        return false;
      }
      return true;
    },
    updateViewModel() {
    },
  },
};
