<template>
  <div class="auto-suggest">
    <ValidationProvider
      ref="fieldValidator"
      v-slot="{ errors }"
      :name="$t(name) || ''"
      :rules="rules || ''"
      :vid="name || name || placeholder || ''"
      mode="eager"
    >
      <div class="d-flex justify-content-between align-items-start gap-1">
        <vue-autosuggest
          ref="autocomplete"
          v-debounce:500ms="fetchResults"
          class="employee-autocomplete w-100"
          :value="defaultValue"
          :suggestions="suggestions"
          :input-props="inputProps"
          :section-configs="sectionConfigs"
          :render-suggestion="renderSuggestion"
          :get-suggestion-value="getSuggestionValue"
          clearable
          debounce-events="input"
        >
          <template slot="before-input">
            <label
              v-if="!hideLabel"
              class="pt-1"
              :class="{
                'required-field': rules && rules.includes('required'),
              }"
              :for="inputProps.id"
            >
              {{ $t(name) }}
            </label>
          </template>
        </vue-autosuggest>
        <div
          class="options d-flex justify-content-between align-items-center gap-1"
        >
          <b-button
            v-b-tooltip.hover.top="$t('unSelectStudent')"
            :disabled="selected == null"
            variant="flat-dark"
            class="btn-icon"
            @click="clearSearch"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>
      </div>
      <small
        :class="{ 'd-none': !name }"
        class="text-danger"
      >
        {{ errors[0] }}
      </small>
    </ValidationProvider>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest';
import { ValidationProvider } from 'vee-validate';

export default {
  emits: ['update:value', 'change'],
  components: {
    VueAutosuggest,
    ValidationProvider,
  },
  props: {
    hideLabel: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'label',
    },
    url: {
      type: String,
      default: null,
    },
    value: {
      type: [Number, String, Object, Array],
      default: null,
    },
    displayItem: {
      type: Object,
      default: () => ({
        id: null,
        code: null,
        uid: '',
        arabicName: '',
        foreignName: '',
      }),
    },
    rules: {
      type: String,
      default: '',
    },
    filterFn: {
      type: Function,
      default: () => true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultValue: null,
      showEmpInfoModal: false,
      query: '',
      timeout: null,
      selected: null,
      debounceMilliseconds: 250,
      inputProps: {
        id: 'autosuggest__input_ajax',
        class: 'form-control',
        name: 'ajax',
        placeholder: `${this.$t('searchForStudentUsingIdentityNumberCodeOrName')}`,
      },
      suggestions: [],
      sectionConfigs: {
        employees: {
          limit: 300,
          onSelected: (selected) => {
            if (selected) {
              const name = selected.item.arabicName;
              this.defaultValue = `${name}`;
            }
            this.selected = selected.item;
            this.$emit('update:value', selected.item.id);
            this.$emit('change:action', selected.item);
          },
        },
      },
    };
  },
  computed: {
    translatedLabel() {
      return this.$t(this.name);
    },
  },
  watch: {
    displayItem(newItem) {
      this.populateDataFromDispalyItem(newItem);
    },
    disabled(newVal) {
      this.inputProps = { ...this.inputProps, disabled: newVal };
    },
  },
  mounted() {
    this.populateDataFromDispalyItem(this.displayItem);
  },
  methods: {
    populateDataFromDispalyItem(newItem) {
      if (newItem && newItem.arabicName) {
        this.selected = newItem;
        const name = newItem.arabicName;
        this.defaultValue = `${name}`;
        this.$emit('change:bind', newItem);
      }
    },
    fetchResults(data) {
      if (data.length < 2) return;
      let query = data;
      const intQuery = +query;
      if (!query || this.disabled) return;
      if (intQuery) {
        query = intQuery.toString();
      }
      this.get({
        url: `${this.url}?search=${query}`,
      }).then((dd) => {
        data = dd;
        this.suggestions = [];
        this.selected = null;
        const filteredData = data.filter(this.filterFn);
        data.length && this.suggestions.push({ name: 'employees', data: filteredData });
      });
    },
    renderSuggestion(suggestion) {
      const empName = suggestion.item.arabicName;
      return (
        <div>
          <div>
            <span>{empName}</span>
          </div>
        </div>
      );
    },
    getSuggestionValue(suggestion) {
      const name = suggestion.item.arabicName;
      return `${name}`;
    },
    showSelectedEmpInfo() {
      if (this.selected) {
        this.showEmpInfoModal = true;
      } else {
        this.$notifications().toast({
          title: 'warning',
          content: 'firstSelectAnEmployee',
          variant: 'warning',
        });
      }
    },
    clearSearch() {
      this.$refs.autocomplete.internalValue = null;
      this.$emit('update:value', null);
      this.$emit('clearEmployee');
    },
  },
};
</script>
<style lang="scss">
// Core variables and mixins
@import '~@core/scss/base/bootstrap-extended/include';
// Overrides user variable
@import '~@core/scss/base/components/include';

.options {
  align-self: flex-end;

  @media (max-width: 25em) {
    align-self: flex-end !important;
    margin-top: 0 !important;
  }
}

// customize the dropdown
.autosuggest__results-container {
  position: relative;
  z-index: 999;

  .autosuggest__results {
    position: absolute;
    background-color: $white;
    width: 100%;
    margin-top: 1rem;
    overflow-y: auto;
    border-radius: 0.5rem;
    max-height: 40vh;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
      0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;

    ul {
      padding-left: 0;
      margin-bottom: 0;

      li {
        list-style: none;
        padding: 0.75rem 1rem;

        &:hover,
        &.autosuggest__results-item--highlighted {
          background: $gray-200;
        }

        .detail {
          line-height: 0.5;
        }
      }

      .autosuggest__results-before {
        color: $primary;
        font-weight: 600;
      }
    }
  }
}

//--------- dark layout ---------//
body {
  &.dark-layout {
    .autosuggest__results-container {
      .autosuggest__results {
        background-color: $theme-dark-input-bg;
        color: $theme-dark-body-color;

        // on hover
        .autosuggest__results-item--highlighted,
        .autosuggest__results-item:hover,
        .autosuggest__results-before:hover {
          background-color: $theme-dark-table-hover-bg;
        }

        .my-suggestion-item {
          color: $theme-dark-body-color;
        }
      }
    }
  }
}

.employee-autocomplete .required-field:after {
  content: ' * ';
  color: #ea5455;
  font-weight: bolder;
  font-size: 1rem;
  line-height: 0;
}

.auto-suggest {
  margin-top: -11px;
}

.auto-suggest label {
  font-size: 14px;
}

.auto-suggest button {
  padding-top: 12px !important;
  margin-left: -2px;
  color: black;
  background-color: #dcdfe8;
}
</style>
