export default {
    tableTemplate: `<table class="table printTable" style="width:100%; text-align:center;">
          <thead>
            <tr>
              <th colspan="3" class="h2" style="padding: 0.75rem;">item</th>
              <th class="text-center h2" style="padding: 0.75rem;">quantity</th>
              <th class="text-center h2" style="padding: 0.75rem;">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in selectedItem.lines" :key="item.id">
            <td colspan="3" class="h3" style="padding: 0.75rem;">
              {{ item.itemArabicName }}
              <ul class="mt-50" v-for="obj in item.additions" :key="obj.id">
              <li><span>+ </span>{{obj.itemArabicName}}</li>
              </ul>
              <div class="mt-50"><span></span>{{item.notes}}</div>
              </td>
              <td scope="row" style="padding: 0.75rem;" class="align-baseline text-center h3">{{ item.quantity }}</td>
              <td class="align-baseline text-center h3" style="padding: 0.75rem;">{{ item.price }}</td>
            </tr>
          </tbody>
          </table>
          <hr>
        `,
    companyLogo: `<div class="text-center row">
      <div class="col-md-12">
        <b-img :src="companyLogo" class="border" :width="200" :height="200" />
      </div>
    </div>`,
    qrCode: `<div class="text-center row">
      <div class="col-md-12">
        <img :src="qrCodeImg" width="400" height="400"/>
      </div>
    </div>`,
    companyName: `<div class="text-center row" style="width:100%; text-align:center;">
      <div class="col-md-12">
        <p>{{ companyName }}</p>
      </div>
    </div>`,
    address: `<div class="text-center row" style="width:100%; text-align:center;">
      <div class="col-md-12">
        <p class="h2">{{ address }}</p>
      </div>
    </div>`,
    taxNumber: `<div class="text-center row" style="width:100%; text-align:center;">
      <div class="col-md-12">
        <p class="h2"> الرقم الضريبي : {{ taxNumber }}</p>
      </div>
    </div>`,
    taxesInvoice: `<hr/><div class="text-center m-auto row" style="width:100%; text-align:center;">
      <div class="text-center m-auto col-md-6">
        <p class="border">{{ taxesInvoice }}</p>
      </div>
    </div>`,
    invoiceCode: `<div class="text-center m-auto row" style="width:100%; text-align:center;">
      <div class="text-center m-auto col-md-4">
        <p class="border"> فاتوره رقم : {{ invoiceCode }} </p>
      </div>
    </div>`,
    transactionDate: `<div class="text-center m-auto row" style="width:100%; text-align:center;">
      <div class="col-md-12 text-center">
        <p class="h3"> الوقت : {{ transactionDate }} </p>
      </div>
    </div>`,
    paymentType: `<div class="text-center m-auto row" style="width:100%; text-align:center;">
    <div class="col-md-12 text-center">
      <p class="h3"> نوع السداد : {{ paymentType }} </p>
    </div>
   </div><hr/>`,
    total: `<div class=" row m-auto text-center" style="width:95%;">
      <div class="col-sm-12 border">
        <p class="">
          <span class="text-right h3" style="float:right !important;">الأجمالي :</span>
          <span class="text-left h3" style="float:left !important;">{{ total }}</span>
        </p>
      </div>
    </div>`,
    netBeforeTax:
    `<div class=" row m-auto text-center" style="width:95%;">
      <div class="col-sm-12 border">
        <p class="">
          <span class="text-right h3" style="float:right !important;">الأجمالي غير شامل الضريبه :</span>
          <span class="text-left h3" style="float:left !important;">{{ netBeforeTax }}</span>
        </p>
      </div>
    </div>`,
    discount: `<div class=" row m-auto text-center" style="width:95%;">
    <div class="col-sm-12 border">
        <p class="">
          <span class="text-right h3" style="float:right !important;">اجمالي الخصومات :</span>
          <span class="text-left h3" style="float:left !important;">%{{ discount }}</span>
        </p>
      </div>
    </div>`,
    taxPercentage: `<div class=" row m-auto text-center" style="width:95%;">
    <div class="col-sm-12 border">
        <p class="">
          <span class="text-right h3" style="float:right !important;">15% ضريبه القيمه المضافه :</span>
          <span class="text-left h3" style="float:left !important;">{{ taxPercentage }}</span>
        </p>
      </div>
    </div>`,
    net: `<div class=" row m-auto text-center" style="width:95%;">
    <div class="col-sm-12 border">
        <p class="">
          <span class="text-right h3" style="float:right !important;">الصافي شامل الضريبه :</span>
          <span class="text-left h3" style="float:left !important;">{{ net }}</span>
        </p>
      </div>
    </div>`,
    userName: `<div class="text-left row" style="width:100%; text-align:center;">
      <div class="text-left col-md-12">
      <p class="h3"> اسم البائع : {{ userName }} </p>
      </div>
    </div>`,
    baseTemplate: `<div style="width: 3.2in">
      {{ companyLogo }}
      <div> {{ companyName }} </div>
      <div> {{ address }} </div>
      <div> {{ taxNumber }} </div>
      <div> {{ invoiceCode }} </div>
      <div> {{ transactionDate }} </div>
      <div> {{ paymentType }} </div>
      <div> {{ items }} </div>
      <div> {{ total }} </div>
      <div> {{ discount }} </div>
      <div> {{ taxPercentage }} </div>
      <div> {{ net }} </div>
      </div>`,
    createReceiptContent: function (
      template,
      tableTemplate,
      companyLogo,
      companyName,
      address,
      taxNumber,
      taxesInvoice,
      invoiceCode,
      transactionDate,
      paymentType,
      total,
      netBeforeTax,
      discount,
      taxPercentage,
      net,
      userName,
      qrCode
    ) {
      template = template || this.baseTemplate;
      tableTemplate = tableTemplate || this.tableTemplate;
      companyLogo = companyLogo || this.companyLogo;
      companyName = companyName || this.companyName;
      address = address || this.address;
      taxNumber = taxNumber || this.taxNumber;
      taxesInvoice = taxesInvoice || this.taxesInvoice;
      invoiceCode = invoiceCode || this.invoiceCode;
      paymentType = paymentType || this.paymentType;
      total = total || this.total;
      netBeforeTax = netBeforeTax || this.netBeforeTax;
      discount = discount || this.discount;
      taxPercentage = taxPercentage || this.taxPercentage;
      net = net || this.net;
      userName = userName || this.userName;
      transactionDate = transactionDate || this.transactionDate;
      qrCode = qrCode || this.qrCode;
      template = template.replace('{{ items }}', tableTemplate);
      template = template.replace('{{ companyLogo }}', companyLogo);
      template = template.replace('{{ companyName }}', companyName);
      template = template.replace('{{ address }}', address);
      template = template.replace('{{ taxNumber }}', taxNumber);
      template = template.replace('{{ taxesInvoice }}', taxesInvoice);
      template = template.replace('{{ invoiceCode }}', invoiceCode);
      template = template.replace('{{ paymentType }}', paymentType);
      template = template.replace('{{ transactionDate }}', transactionDate);
      template = template.replace('{{ total }}', total);
      template = template.replace('{{ netBeforeTax }}', netBeforeTax);
      template = template.replace('{{ discount }}', discount);
      template = template.replace('{{ taxPercentage }}', taxPercentage);
      template = template.replace('{{ net }}', net);
      template = template.replace('{{ userName }}', userName);
      template = template.replace('{{ qrCodeValue }}', qrCode);
      return `<div style="width:100%; text-align:center;">${template}</div>`;
    },
  };
