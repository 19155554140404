import Vue from 'vue';

export default ({ template, data }) => {
  let el = Vue.compile(template);
  el = new Vue({
    name: 'render',
    render: el.render,
    data() {
      return {
        ...data,
      };
    },
    staticRenderFns: el.staticRenderFns,
  }).$mount();
  return el.$el.innerHTML;
};
